import { isNullish } from '@/utils/common/isNullish';

export type RemoveSuffix<T, Suffix extends string> = T extends `${infer Prefix}${Suffix}`
  ? Prefix
  : never;

export function assertNever(value: never, message?: string): never {
  throw new Error(message ?? `Illegal value: ${value}`);
}

export type RequiredNotNull<T> = {
  [P in keyof T]-?: NonNullable<T[P]>;
};

export function assertFieldNotNullish<T, K extends keyof T>(
  val: T,
  key: K
): asserts val is T & RequiredNotNull<Pick<T, K>> {
  if (isNullish(val[key])) {
    throw new Error(`${String(key)} is required`);
  }
}
